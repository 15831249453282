import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Modal from '../../../../../components/modal/modal';
import InfoBtn from '../../../../../components/infoBtn/infoBtn';

import nl2br from '../../../../../utilities/string/nl2br';
import InfoButtonHelperText from 'src/components/infoButtonHelperText/infoButtonHelperText';

function IntakeModal() {
  const { t } = useTranslation();
  const [active, setActive] = useState(false);

  function showModal() {
    setActive(true);
  }

  function hideModal() {
    setActive(false);
  }

  return (
    <>
      <div className="float-right">
        <InfoBtn
          onClick={showModal}
          id={`${t('project.new.intake.title')}-infoButton`}
        />
      </div>
      <div className="float-right px-2">
        <InfoButtonHelperText
          buttonId={`${t('project.new.intake.title')}-infoButton`}
        />
      </div>
      <Modal
        title={t('project.new.intake.title')}
        active={active}
        onClose={hideModal}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: nl2br(t('project.new.intake.text')),
          }}
        />
      </Modal>
    </>
  );
}

export default IntakeModal;
