import MaritImage from './assets/CCV_Marit_van_Wijncoop.jpg';
import SaraImage from './assets/CCV_Sara_Wester.jpg';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function TeamMembers() {
  const { t } = useTranslation();

  return (
    <div className="row">
      <div className="col-6">
        <img
          src={MaritImage}
          alt="Marit van Wijncoop"
          className="img-thumbnail mb-3"
          style={{
            aspectRatio: '1/1',
            objectFit: 'cover',
          }}
        />
        <h3 className="mb-1">Marit van Wijncoop</h3>
        <p>{t('teamMembers_marit_role')}</p>
        <p>
          <a href="tel:06 27 50 61 52">06 27 50 61 52</a>
          <br />
          <a href="mailto:marit.vanwijncoop@hetccv.nl ">
            marit.vanwijncoop@hetccv.nl{' '}
          </a>
        </p>
      </div>
      <div className="col-6">
        <img
          src={SaraImage}
          alt="Sara Wester"
          className="img-thumbnail mb-3"
          style={{
            aspectRatio: '1/1',
            objectFit: 'cover',
          }}
        />
        <h3 className="mb-1">Sara Wester</h3>
        <p>{t('teamMembers_sara_role')}</p>
        <p>
          <a href="tel:06 21 952 943">06 21 952 943</a>
          <br />
          <a href="mailto:sara.wester@hetccv.nl">sara.wester@hetccv.nl</a>
        </p>
      </div>
    </div>
  );
}
