import * as d3 from 'd3';
import cn from 'classnames';

const partition = d3.partition();
let circle;
let outerCircleSlices;
let outerCirclePaths;
let innerCirclePaths;
let textPaths;
let textHiddenPaths;
let textLabels;
let arc;
let x;
let y;
let arcAnswers;
let xAnswers;
let yAnswers;
let middleArcLine;
const answerStash = {};

function stash(d, i) {
  if (!answerStash[i]) {
    answerStash[i] = {};
  }
  answerStash[i].x0 = d.x0;
  answerStash[i].x1 = d.x1;
}

class SunburstService {
  listeners = [];

  constructor({
    labels,
    interactive,
    size,
    answerCircleSize,
    onOpenModal,
    onZoomStart,
    onZoomFinished,
    meantForDownload = false,
  }) {
    this._showLabels = labels;
    this._isInteractive = interactive;
    this._sunburstSize = size;
    this._radius = size / 2;
    this._answerCircleSize = answerCircleSize;
    this._answerCircleRadius = answerCircleSize / 2;
    this._zoomLevel = 0;
    this._zoomLayers = [];
    this._hasZoomed = false;
    this._meantForDownload = meantForDownload;
    this._projectType = null;

    this._sunburstData = null;
    this._analysisAnswerData = null;

    this.onZoomStart = onZoomStart;
    this.onZoomFinished = onZoomFinished;
    this.onOpenModal = onOpenModal;
  }

  // public
  subscribe(listener) {
    this.listeners.push(listener);

    return () => {
      const i = this.listeners.indexOf(listener);
      if (i >= 0) {
        this.listeners.splice(i, 1);
      }
    };
  }

  // private
  notify() {
    for (const listener of this.listeners) {
      listener(this);
    }
  }

  getZoomLevelInfo() {
    return {
      level: this._zoomLevel,
      data: this._zoomLevelData,
      layers: this._zoomLayers,
    };
  }

  handleData(sunburstData, analysisQuestions, projectType) {
    this._projectType = projectType;
    this._sunburstData = sunburstData;
    this._analysisAnswerData =
      SunburstService.transformAnalysisQuestionsToSunburstData(
        analysisQuestions
      );
  }

  initialiseSunburst(target, sunburstData, analysisQuestions, projectType) {
    this.handleData(sunburstData, analysisQuestions, projectType);

    this._svg = d3
      .select(target)
      .append('svg')
      .attr('id', 'sunburstSvg')
      .attr('width', this._sunburstSize)
      .attr('height', this._sunburstSize);

    circle = this._svg
      .append('g')
      .attr(
        'transform',
        'translate(' +
          this._sunburstSize / 2 +
          ',' +
          this._sunburstSize / 2 +
          ')'
      );

    // outer ring arcs
    x = d3.scaleLinear().range([0, 2 * Math.PI]);
    y = d3.scaleSqrt().range([57, this._radius]);

    // start positions for outer ring (categories and children)
    // https://github.com/mbostock/d3/wiki/SVG-Shapes#arc
    arc = d3
      .arc()
      .startAngle((d) => Math.max(0, Math.min(2 * Math.PI, x(d.x0))))
      .endAngle((d) => Math.max(0, Math.min(2 * Math.PI, x(d.x1))))
      .innerRadius((d) => Math.max(0, y(d.y0)))
      .outerRadius((d) => Math.max(0, y(d.y1)));

    // inner ring arcs
    xAnswers = d3.scaleLinear().range([0, 2 * Math.PI]);
    yAnswers = d3.scaleSqrt().range([-100, this._answerCircleRadius]);

    // start positions for inner ring (answers)
    arcAnswers = d3
      .arc()
      .startAngle((d) => Math.max(0, Math.min(2 * Math.PI, xAnswers(d.x0))))
      .endAngle((d) => Math.max(0, Math.min(2 * Math.PI, xAnswers(d.x1))))
      .innerRadius((d) => Math.max(0, yAnswers(d.y0)))
      .outerRadius((d) => Math.max(0, yAnswers(d.y1)));

    this.defineStripedPatterns();
    this.createOuterCircle();
    this.createInnerCircle();
    if (this._showLabels) this.addLabels();
  }

  // based on http://bl.ocks.org/Rokotyan/0556f8facbaf344507cdc45dc3622177
  getPngImage(callback) {
    const svgString = getSVGString(this._svg.node());
    svgStringToPng(
      svgString,
      2 * this._sunburstSize,
      2 * this._sunburstSize,
      callback
    ); // passes Blob and filesize String to the callback

    // Below are the functions that handle actual exporting:
    // getSVGString ( svgNode ) and svgStringToPng( svgString, width, height, callback )
    function getSVGString(svgNode) {
      svgNode.setAttribute('xlink', 'http://www.w3.org/1999/xlink');
      const cssStyleText = getCSSStyles(svgNode);
      appendCSS(cssStyleText, svgNode);

      const serializer = new XMLSerializer();
      let svgString = serializer.serializeToString(svgNode);
      svgString = svgString.replace(/(\w+)?:?xlink=/g, 'xmlns:xlink='); // Fix root xlink without namespace
      svgString = svgString.replace(/NS\d+:href/g, 'xlink:href'); // Safari NS namespace fix

      return svgString;

      function getCSSStyles(parentElement) {
        const nodesToCheck = [parentElement];

        // Add all the different nodes to check
        const childNodes = parentElement.getElementsByTagName('*');
        for (let i = 0; i < childNodes.length; i++) {
          nodesToCheck.push(childNodes[i]);
        }

        // Extract CSS Rules
        const extractedCSSRules = [];
        for (let i = 0; i < document.styleSheets.length; i++) {
          const s = document.styleSheets[i];

          try {
            if (!s.cssRules) continue;
          } catch (e) {
            if (e.name !== 'SecurityError') throw e; // for Firefox
            continue;
          }

          const cssRules = s.cssRules;
          let ruleMatches;
          for (let r = 0; r < cssRules.length; r++) {
            ruleMatches = nodesToCheck.reduce(function (a, b) {
              return a || b.matches(cssRules[r].selectorText);
            }, false);
            if (ruleMatches) extractedCSSRules.push(cssRules[r].cssText);
          }
        }
        return extractedCSSRules.join(' ');
      }

      function appendCSS(cssText, element) {
        const styleElement = document.createElement('style');
        styleElement.setAttribute('type', 'text/css');
        styleElement.innerHTML = cssText;
        const refNode = element.hasChildNodes() ? element.children[0] : null;
        element.insertBefore(styleElement, refNode);
      }
    }

    function svgStringToPng(svgString, width, height, callback) {
      const imgsrc =
        'data:image/svg+xml;base64,' +
        btoa(unescape(encodeURIComponent(svgString))); // Convert SVG string to data URL

      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');

      canvas.width = width;
      canvas.height = height;

      const image = new Image();
      image.onload = function () {
        context.clearRect(0, 0, width, height);
        context.drawImage(image, 0, 0, width, height);

        const png = canvas.toDataURL('image/png');
        callback(png);
        // canvas.toBlob(callback);
      };

      image.src = imgsrc;
    }
  }

  defineStripedPatterns() {
    const defs = this._svg.append('defs');
    this._sunburstData.children.forEach((category) => {
      defs
        .append('pattern')
        .attr('id', `striped_${category.numId}`)
        .attr('width', '3')
        .attr('height', '1')
        .attr('patternUnits', 'userSpaceOnUse')
        .append('rect')
        .attr('width', '1')
        .attr('height', '1')
        .attr('transform', 'translate(0,0)')
        .attr('fill', category.colors[0]);
    });
  }

  createOuterCircle() {
    const root = d3.hierarchy(this._sunburstData);
    const circleData = partition(root).descendants();

    root.sum((d) => {
      if (d.type === 'intervention') {
        const interventionNode = circleData.find(
          (node) => node.data.type === 'intervention' && node.data.id === d.id
        );
        const mechanismNode = interventionNode.parent;
        return (
          mechanismNode.data[`questionCountProjectType${this._projectType}`] /
          SunburstService.getMechanismLeafCount(mechanismNode)
        );
      }
      return 0;
    });

    // create outer circle
    const outerCircle = circle
      .selectAll('.category-circle')
      .data(partition(root).descendants());

    outerCircleSlices = outerCircle.enter().append('g').attr('class', 'slice');

    outerCirclePaths = outerCircleSlices
      .append('path')
      .attr('d', arc)
      .attr('id', ({ data }) =>
        data.isRoot ? 'root_node' : `${data.type}_${data.id}`
      )
      .attr('class', (node) => {
        const mechanismNode = SunburstService.getMechanismForNode(node);
        const categoryNode = SunburstService.getCategoryForNode(node);
        return cn(node.data.type, 'category-circle', {
          [`${node.data.type}_${node.data.id}`]: node.data.type,
          [`belongs_to_mechanism_${mechanismNode?.data.id}`]: mechanismNode,
          [`belongs_to_category_${categoryNode?.data.id}`]: categoryNode,
        });
      })
      .style('fill', ({ data }) => data.color)
      .style('stroke', ({ data }) =>
        data.color === 'transparent' ? data.color : '#FFFFFF'
      );

    if (this._isInteractive) {
      outerCirclePaths
        .on('mouseenter', (event, d) => this.handleOuterCircleMouseEnter(d))
        .on('mouseout', (event, d) => this.handleOuterCircleMouseLeave(d))
        .on('click', (event, d) => this.handleOuterCircleClick(d));
    }
  }

  createInnerCircle() {
    const root = d3.hierarchy(this._analysisAnswerData);
    root.sum((d) => d.value || 0);

    // create inner circle
    innerCirclePaths = circle
      .selectAll('.answer')
      .data(partition(root).descendants())
      .enter()
      .append('path')
      .attr('d', arcAnswers)
      .attr('id', ({ data }) => {
        if (data.category !== undefined) {
          return data.category;
        } else if (data.score !== undefined) {
          return 'answer_' + data.id;
        } else if (data.id !== undefined) {
          return data.id;
        }
        return null;
      })
      .attr('class', (node) => {
        const questionNode = SunburstService.getQuestionNodeForAnswerNode(node);
        return cn({
          [`answer`]: node.data.score === undefined,
          [`belongs_to_question_${questionNode?.data.id}`]: questionNode,
        });
      })
      .style('fill', ({ data }) => data.color)
      .style('stroke', ({ data }) => data.color)
      .each(stash);

    if (this._isInteractive) {
      innerCirclePaths
        .on('mouseenter', (event, d) => this.handleInnerCircleMouseEnter(d))
        .on('mouseout', (event, d) => this.handleInnerCircleMouseLeave(d))
        .on('click', (event, d) => this.handleInnerCircleClick(d));
    }
  }

  addLabels() {
    middleArcLine = (d) => {
      const halfPi = Math.PI / 2;
      const angles = [x(d.x0) - halfPi, x(d.x1) - halfPi];
      const r = Math.max(0, (y(d.y0) + y(d.y1)) / 2);

      const middleAngle = (angles[1] + angles[0]) / 2;
      const invertDirection = middleAngle > 0 && middleAngle < Math.PI; // On lower quadrants write text ccw
      if (invertDirection) {
        angles.reverse();
      }

      const path = d3.path();
      path.arc(0, 0, r, angles[0], angles[1], invertDirection);
      return path.toString();
    };

    textHiddenPaths = outerCircleSlices
      .append('path')
      .attr('class', 'hidden-arc')
      .attr('id', (_, i) => `hiddenArc${i}`)
      .attr('d', middleArcLine);

    textLabels = outerCircleSlices.append('text');

    textPaths = textLabels
      .append('textPath')
      .attr('startOffset', '50%')
      .attr('xlink:href', (_, i) => `#hiddenArc${i}`)
      .attr('class', ({ data }) => `text text-${data.type}`)
      .style('fill', '#FFF')
      .text(({ data }) => {
        // set text
        if (data.type === 'category' && this._zoomLevel >= 1) {
          return data.name;
        } else if (data.type === 'mechanism' && this._zoomLevel >= 2) {
          return data.name;
        }
        return data.nameShort;
      })
      .attr('display', ({ data }) =>
        'category' === data.type ? 'block' : 'none'
      );

    if (this._meantForDownload) {
      textPaths
        .style('font-family', 'Helvetica, Arial')
        .style('font-size', '14px')
        .attr('letter-spacing', () => '-1px');
    }
  }

  zoom(root, newZoomLevel) {
    const hasZoomedOut = this._zoomLevel > newZoomLevel;

    this.onZoomStart(newZoomLevel, !this._hasZoomed);
    if (!this._hasZoomed) {
      this._hasZoomed = true;
    }

    Promise.allSettled([
      this.zoomOuterCircle(root, newZoomLevel),
      this.zoomInnerCircle(root, newZoomLevel),
    ]).then(() => {
      let parentId = null;
      if (root?.parent) {
        parentId = root.parent.data.isRoot
          ? 'root_node'
          : `${root.parent.data.type}_${root.parent.data.id}`;
      }
      this._zoomLevelData = root;

      if (hasZoomedOut) this._zoomLayers.pop();
      else this._zoomLayers.push(root.data.id);

      this._zoomLevel = newZoomLevel;
      this.onZoomFinished(newZoomLevel, root.data, parentId);
    });
  }

  zoomOuterCircle(root, newZoomLevel) {
    return new Promise((resolve) => {
      outerCirclePaths
        .style('fill', ({ data }) => {
          if (
            this._zoomLevel === 2 &&
            newZoomLevel === 1 &&
            data.type === 'category'
          ) {
            return '#FFFFFF';
          } else {
            return data.color;
          }
        })
        .style('stroke', (n) => {
          if (SunburstService.isNodeParentOfNode(root, n)) {
            return '#FFFFFF';
          }
          return 'transparent';
        })
        .style('opacity', ({ data }) => data.opacity || 1)
        .style('display', 'block')
        .transition()
        .duration(1000)
        .attrTween('d', this.tweenOuterRingArcs(root))
        .style('fill', ({ data }) => {
          if (data.type === 'category') {
            if (newZoomLevel === 2) {
              return '#FFFFFF';
            } else if (this._zoomLevel === 2 && newZoomLevel === 1) {
              return data.color;
            }
          }
          return data.color;
        })
        .on('end', () => {
          if (newZoomLevel === 2) {
            circle.selectAll('.category').style('display', 'none');
            circle
              .selectAll('.category_' + root.parent.data.id)
              .style('display', 'block');
          }
          resolve();
        });

      textHiddenPaths
        .transition()
        .duration(1000)
        .attrTween('d', (d) => () => middleArcLine(d));

      textPaths
        .text(({ data }) => {
          // set text
          if (data.type === 'category' && newZoomLevel >= 1) {
            return data.name;
          } else if (data.type === 'mechanism' && newZoomLevel >= 2) {
            return data.name;
          }
          return data.nameShort;
        })
        .transition()
        .duration(1000)
        .attrTween('display', (node) => () => {
          const { data: d } = node;
          let display = 'none';
          if (newZoomLevel === 0 && 'category' === d.type) {
            display = 'block';
          } else if (
            newZoomLevel === 1 &&
            ['category', 'mechanism'].includes(d.type)
          ) {
            const category = SunburstService.getCategoryForNode(node);
            if (category && category.data.id === root.data.id) {
              display = 'block';
            }
          } else if (
            newZoomLevel === 2 &&
            ['mechanism', 'intervention'].includes(d.type)
          ) {
            const mechanism = SunburstService.getMechanismForNode(node);
            if (mechanism && mechanism.data.id === root.data.id) {
              display = 'block';
            }
          }
          return display;
        });
    });
  }

  tweenOuterRingArcs(targetRoot) {
    const xd = d3.interpolate(x.domain(), [targetRoot.x0, targetRoot.x1]),
      yd = d3.interpolate(y.domain(), [targetRoot.y0, 1]),
      yr = d3.interpolate(y.range(), [
        targetRoot.y0 ? 65 : 57,
        targetRoot.y0 ? this._radius / 1.45 : this._radius,
      ]);
    return (d, i) => {
      if (i) {
        return () => arc(d);
      } else {
        return (t) => {
          x.domain(xd(t));
          y.domain(yd(t)).range(yr(t));
          return arc(d);
        };
      }
    };
  }

  zoomInnerCircle(root, newZoomLevel) {
    return new Promise((resolve) => {
      if (
        (this._zoomLevel === 1 && newZoomLevel === 0) ||
        this._zoomLevel === 0
      ) {
        innerCirclePaths
          .style('display', ({ data }) => {
            if (
              data.color === '#FFFFFF' &&
              (data.score === undefined || data.score === null)
            ) {
              return 'none';
            }
            return 'block';
          })
          .style('opacity', ({ data }) => {
            if (this._zoomLevel > 0 && data.score === undefined) {
              return 0;
            }
            return data.opacity || 1;
          })
          .style('fill', (node) => {
            const { data } = node;
            if (this._zoomLevel > 0 && data.score !== undefined) {
              return node.parent.data.colors[data.score < 0 ? 0 : data.score];
            } else {
              if (data.color.indexOf('url') > -1) {
                return node.parent.parent.data.colors[0];
              } else {
                return data.color;
              }
            }
          })
          .style('stroke', (node) =>
            newZoomLevel > 0 && node.depth === 2 ? '#FFFFFF' : 'transparent'
          )
          .transition()
          .duration(1000)
          .style('fill', (node) => {
            const { data } = node;
            if (data.score !== undefined && data.score !== null) {
              if (
                this._zoomLevel === 0 &&
                node.parent.data.colors !== undefined
              ) {
                if (data.score < 0) {
                  return 'url(#striped_' + node.parent.data.id + ')';
                } else {
                  return node.parent.data.colors[data.score];
                }
              } else {
                return '#FFFFFF';
              }
            } else if (node.depth > 1) {
              return this._zoomLevel === 0 ? '#FFFFFF' : data.color;
            }
            return 'transparent';
          })
          .style('opacity', (node) => {
            const { data } = node;
            if (
              node.depth > 1 &&
              (data.score === undefined || data.score === null) &&
              this._zoomLevel === 0
            ) {
              return 0;
            }
            return data.opacity || 1;
          })
          .attrTween('d', this.tweenAnswerArcs(root))
          .on('end', () => {
            if (newZoomLevel > 0) {
              circle
                .selectAll('.answer')
                .style('fill', 'transparent')
                .style('stroke', 'transparent')
                .style('display', 'none');
            }
            resolve();
          });
      } else {
        innerCirclePaths
          .transition()
          .duration(1000)
          .attrTween('d', this.tweenAnswerArcs(root, true))
          .on('end', () => resolve());
      }
    });
  }

  tweenAnswerArcs(targetRoot, isMechanism = false) {
    const xd = d3.interpolate(xAnswers.domain(), [
      targetRoot.x0,
      targetRoot.x1,
    ]);
    const yd = d3.interpolate(yAnswers.domain(), [
      targetRoot.y0,
      targetRoot.y0 ? 0.62 : 1,
    ]);
    const yr = d3.interpolate(yAnswers.range(), [
      targetRoot.y0 ? 30 : -100,
      this._answerCircleRadius,
    ]);
    return (d, i) => {
      if (i) {
        return () => arcAnswers(d);
      } else {
        return (t) => {
          xAnswers.domain(xd(t));
          if (!isMechanism) {
            yAnswers.domain(yd(t)).range(yr(t));
          }
          return arcAnswers(d);
        };
      }
    };
  }

  handleOuterCircleClick(node) {
    const { data } = node;
    if (this._zoomLevel === 0) {
      // find the category we need to zoom into
      const category = SunburstService.getCategoryForNode(node);
      if (category) {
        this.zoom(category, 1);
      }
    } else if (
      (this._zoomLevel === 1 && data.isRoot === true) ||
      (this._zoomLevel === 2 && data.type === 'category')
    ) {
      this.zoom(node, this._zoomLevel - 1);
    } else if (this._zoomLevel === 1) {
      if (data.type === 'category') {
        this.openModal(node); // show info on category
      } else {
        const mechanism = SunburstService.getMechanismForNode(node);
        if (mechanism) {
          this.zoom(mechanism, 2);
        }
      }
    } else if (this._zoomLevel === 2) {
      //here we want to see information, income the popups
      if (data.type === 'mechanism') {
        this.openModal(node);
      } else {
        this.openModal(node, this.openModal);
      }
    }
  }

  handleOuterCircleMouseEnter(n) {
    if (!n.data.isRoot) {
      if (this._zoomLevel === 0) {
        // highest zoomLevel
        const category = SunburstService.getCategoryForNode(n);
        circle
          .selectAll('.belongs_to_category_' + category.data.id)
          .style('opacity', ({ data }) =>
            !data.opacity || data.opacity >= 0.7 ? 0.7 : 0.3
          );
      } else if (this._zoomLevel === 1) {
        // category zoomLevel
        if (n.data.type === 'category') {
          circle
            .selectAll('.category_' + n.data.id)
            .style('opacity', ({ data }) =>
              !data.opacity || data.opacity >= 0.7 ? 0.7 : 0.3
            );
        } else {
          const mechanism = SunburstService.getMechanismForNode(n);
          circle
            .selectAll('.belongs_to_mechanism_' + mechanism.data.id)
            .style('opacity', ({ data }) =>
              !data.opacity || data.opacity >= 0.7 ? 0.7 : 0.3
            );
        }
      } else if (this._zoomLevel === 2) {
        circle
          .selectAll(`.${n.data.type}_${n.data.id}`)
          .style('opacity', ({ data }) =>
            !data.opacity || data.opacity >= 0.7 ? 0.7 : 0.3
          );
      }
    }
  }

  handleOuterCircleMouseLeave(n) {
    if (!n.data.isRoot) {
      if (this._zoomLevel === 0) {
        const category = SunburstService.getCategoryForNode(n);
        circle
          .selectAll('.belongs_to_category_' + category.data.id)
          .style('opacity', ({ data }) =>
            !data.opacity || data.opacity >= 0.7 ? 1 : 0.2
          );
      } else if (this._zoomLevel === 1) {
        if (n.data.type === 'category') {
          circle.selectAll('.category_' + n.data.id).style('opacity', null);
        } else {
          const mechanism = SunburstService.getMechanismForNode(n);
          circle
            .selectAll('.belongs_to_mechanism_' + mechanism.data.id)
            .style('opacity', ({ data }) =>
              !data.opacity || data.opacity >= 0.7 ? 1 : 0.2
            );
        }
      } else if (this._zoomLevel === 2) {
        circle.selectAll(`.${n.data.type}_${n.data.id}`).style('opacity', null);
      }
    }
  }

  handleInnerCircleMouseEnter(n) {
    // highlight question
    if (this._zoomLevel === 0) {
      const questionNode = SunburstService.getQuestionNodeForAnswerNode(n);
      if (questionNode && questionNode.data.question !== undefined) {
        circle
          .selectAll('.belongs_to_question_' + questionNode.data.id)
          .style('opacity', ({ data }) =>
            !data.opacity || data.opacity >= 0.7 ? 0.7 : 0.3
          );
      }
    } else if (this._zoomLevel > 0) {
      circle.selectAll('#answer_' + n.data.id).style('opacity', 0.7);
    }
  }

  handleInnerCircleMouseLeave(n) {
    // unhighlight question
    if (this._zoomLevel === 0) {
      const questionNode = SunburstService.getQuestionNodeForAnswerNode(n);
      if (questionNode && questionNode.data.question !== undefined) {
        circle
          .selectAll('.belongs_to_question_' + questionNode.data.id)
          .style('opacity', ({ data }) =>
            !data.opacity || data.opacity >= 0.7 ? 1 : 0.2
          );
      }
    } else if (this._zoomLevel > 0) {
      circle.selectAll('#answer_' + n.data.id).style('opacity', 1);
    }
  }

  handleInnerCircleClick(n) {
    if (this._zoomLevel === 0 && n.data.depth <= 2) {
      return;
    }
    const questionNode = SunburstService.getQuestionNodeForAnswerNode(n);
    if (questionNode && questionNode.data.question !== undefined) {
      // clicked on a question, show it
      this.openModal(questionNode);
    }
  }

  openModal(node) {
    if (node.data.type) {
      switch (node.data.type) {
        case 'question':
          this.onOpenModal({
            type: node.data.type,
            colors: node.parent.data.colors,
            question: node.data,
          });
          break;
        case 'intervention':
          const categoryNode = SunburstService.getCategoryForNode(node);
          this.onOpenModal({
            type: node.data.type,
            title: `content.${node.data.type}.${
              node.data.type === 'category' ? node.data.numId : node.data.id
            }.title`,
            content: `content.${node.data.type}.${
              node.data.type === 'category' ? node.data.numId : node.data.id
            }.text`,
            color: node.data.color,
            exampleIds: node.data.examples,
            categoryColors: categoryNode.data.colors,
          });
          break;
        case 'category':
        case 'mechanism':
        default:
          this.onOpenModal({
            type: node.data.type,
            title: `content.${node.data.type}.${
              node.data.type === 'category' ? node.data.numId : node.data.id
            }.title`,
            content: `content.${node.data.type}.${
              node.data.type === 'category' ? node.data.numId : node.data.id
            }.text`,
            color: node.data.color,
          });
          break;
      }
    }
  }

  static isNodeParentOfNode(searchNode, n) {
    let node = { ...n };

    const isSameNode = (node1, node2) =>
      node1 &&
      node2 &&
      node1.data.id === node2.data.id &&
      node1.data.type === node2.data.type;

    if (isSameNode(node, searchNode)) {
      return true;
    }
    while (!isSameNode(node.parent, searchNode) && node.parent) {
      node = node.parent;
    }
    return node.parent === searchNode;
  }

  static getMechanismLeafCount(n) {
    let count = 0; // amount of leaf nodes

    if (n.children === undefined) return 1;

    n.children.forEach(function (c) {
      count += SunburstService.getMechanismLeafCount(c);
    });

    return count;
  }

  static getMechanismForNode(n) {
    let node = n;
    while (
      node.data.type !== 'mechanism' &&
      node.parent &&
      node.data.type !== 'category'
    ) {
      node = node.parent;
    }

    if (node.data.type === 'mechanism') {
      return node;
    }
    return null;
  }

  static getCategoryForNode(n) {
    let node = n;
    while (node.data.type !== 'category' && node.parent) {
      node = node.parent;
    }

    if (node.data.type === 'category') {
      return node;
    }
    return null;
  }

  static getQuestionNodeForAnswerNode(n) {
    let node = n;
    while (node.data.question === undefined && node.parent) {
      node = node.parent;
    }

    if (node.data.question !== undefined) {
      return node;
    }
    return null;
  }

  static getNodeWithMechanismIdForNode(n) {
    let node = n;

    while (node.data.mechanismId === undefined && node.parent) {
      node = node.parent;
    }

    if (node.data.mechanismId !== undefined) {
      return node;
    }
    return null;
  }

  static getParentNodeWithTargetForNode(n) {
    let node = n;
    while (node.data.target === undefined && node.parent) {
      node = node.parent;
    }

    if (node.data.target !== undefined) {
      return node;
    }
    return null;
  }

  static transformAnalysisQuestionsToSunburstData(
    analysisQuestions,
    zoomedMechanismId = null
  ) {
    const sunburstData = analysisQuestions.map((analysisQuestionCategory) => {
      const colors = analysisQuestionCategory.colors;
      return {
        ...analysisQuestionCategory,
        color: 'transparent',
        children: analysisQuestionCategory.children.map((question) => {
          let showQuestion = true;
          let rootChild;
          let child;
          let currentChild;
          if (
            zoomedMechanismId !== null &&
            zoomedMechanismId !== question.mechanismId
          ) {
            showQuestion = false;
          }

          // add a subsequent children for each score point
          for (let j = 0; j <= 5; j++) {
            if (question.score === -1 && j === 0) {
              child = {
                color: `url(#striped_${analysisQuestionCategory.id})`,
              };
            } else {
              child = {
                color:
                  j <= question.score && question.score !== null
                    ? colors[j]
                    : '#FFFFFF',
              };
            }

            if (j === 5) {
              //give last node a value
              child.value = showQuestion ? 1 : 0;
            }

            if (j === 0) {
              rootChild = child;
            } else {
              currentChild.children = [child];
            }
            currentChild = child;
          }

          return {
            ...question,
            children: [rootChild],
            type: 'question',
            color: '#FFFFFF',
          };
        }),
      };
    });

    return {
      id: 'root',
      color: 'transparent',
      children: sunburstData,
    };
  }

  setActiveDistributionTargetGroups(targetGroups) {
    circle
      .selectAll('.category-circle')
      .transition()
      .duration(400)
      .style('opacity', (node) => {
        node.data.opacity = 1;
        if (targetGroups && targetGroups.length === 0) {
          return 1;
        }

        const targetNode = SunburstService.getParentNodeWithTargetForNode(node);
        if (
          targetNode &&
          targetNode.data.target.some((group) => targetGroups.includes(group))
        ) {
          return 1;
        }
        node.data.opacity = 0.2;
        return 0.2;
      });
    // highlight active answers
    circle
      .selectAll('.answer')
      .transition()
      .duration(400)
      .style('opacity', (node) => {
        node.data.opacity = 1;
        if (targetGroups && targetGroups.length === 0) {
          return 1;
        }

        const nodeWithMechanismId =
          SunburstService.getNodeWithMechanismIdForNode(node);
        let mechanismNode;
        if (nodeWithMechanismId) {
          mechanismNode = circle
            .select(`.mechanism_${nodeWithMechanismId.data.mechanismId}`)
            .data()[0];
        }

        if (
          mechanismNode &&
          mechanismNode.data.target &&
          mechanismNode.data.target.some((group) =>
            targetGroups.includes(group)
          )
        ) {
          return 1;
        }
        node.data.opacity = 0.2;
        return 0.2;
      });
  }
}

export default SunburstService;
