const content = {
  home: {
    teaser: {
      title:
        'Met het InterventieKompas ontdek je hoe je gewenst gedrag kunt stimuleren.',
      description:
        'Vraag jij je als handhaver, toezichthouder of beleidsontwikkelaar af waarom personen, bedrijven of organisaties de regels niet naleven?',
    },
    main: {
      title: 'InterventieKompas',
      text: 'Het instrument, ontwikkeld door het Centrum voor Criminaliteitspreventie en Veiligheid (het CCV), helpt je om stapsgewijs de motieven voor het wel of niet naleven van regels in kaart te brengen en biedt op basis van je analyse oplossingsrichtingen.',
    },
    howItWorks: {
      title: 'Hoe het InterventieKompas werkt',
      text: 'Het instrument is een gestructureerde vragenlijst waarmee jij met de doelgroep zelf of met mensen die de doelgroep kennen in gesprek kunt gaan en in vier stappen kunt achterhalen waarom een bepaalde groep zich niet (of wel) aan de regels houdt. Deze inzichten geven jou de ‘knoppen om aan te draaien’ om het gewenste gedrag te stimuleren. Daarbij geeft het InterventieKompas ter inspiratie voorbeelden van (gedrags)interventies die je kunt gebruiken voor een passende aanpak. Het instrument is gemaakt volgens de laatste inzichten binnen de gedragswetenschap.',
    },
    introSteps: {
      title: 'De stappen uit het InterventieKompas',
    },
    rightColumn: {
      title: 'CCV-trainingen',
      text: 'Wil je meer verdieping over hoe je gedragskennis toepast in je eigen praktijk? Het CCV heeft brede en actuele kennis op het gebied van gedragsbeïnvloeding en nalevingsbevordering en de invloed van organisatiecultuur. Dat brengen we graag op je over via onze trainingen. Bekijk ons <1>trainingsoverzicht</1> op de CCV-website.',
    },
    leftColumn: {
      title: 'Het CCV helpt',
      text:
        'Wil je aan de slag met het InterventieKompas, en kun je wel wat hulp gebruiken?\n\n' +
        '<1>Volg de CCV-training InterventieKompas</1> waarin je leert hoe je het instrument kunt toepassen en wat het voor jouw organisatie kan opleveren. Of schakel de procesbegeleiders van het CCV in om een sessie op maat te begeleiden.\n\n' +
        'Neem <3>contact</3> met het CCV op en vraag naar onze mogelijkheden.\n\n' +
        'Wil je liever eerst wat lezen over de werkwijze en de mechanismen en achtergronden van het Interventiekompas, lees dan de <5>handleiding</5>.',
    },
  },
  introSteps: {
    list: {
      1: {
        title: 'Enter project details',
        text: 'Describe the problem and the regulation that should ensure that this problem is resolved. Then decide which target group you want to investigate further.',
      },
      2: {
        title: 'Target group analysis',
        text: "Analyse the target group's motivation for not complying with the regulation. Does the target group know the regulation? Does the target group violate the regulation out of habit? Or is it very difficult to comply with the regulation? And what are the actual risks if the target group violates the regulation?",
      },
      3: {
        title: 'Subdivision of target group',
        text: 'Further elaborate the group. Does it involved intentional or unintentional offenders? Are there individuals who are deterred by enforcement? Identify the different types of offenders and establish the group where the greatest effect can be achieved.',
      },
      4: {
        title: 'Determine a course',
        text: 'All information comes together. The InterventieKompas shows where the greatest opportunities for effective intervention lie. Explanations of the interventions and practical examples help you along your way.',
      },
      5: {
        title: 'Project overview',
        text: 'Hier zie je in een oogopslag de visuele samenvatting van alle eerdere stappen die je hebt gezet en vind je ook de opgeslagen interventie-ideeën terug.',
      },
    },
  },
  conditions: {
    title: 'Gebruikersvoorwaarden',
    lead: 'Het InterventieKompas is - in opdracht van het ministerie van Justitie en Veiligheid - ontwikkeld door en eigendom van het Centrum voor Criminaliteitspreventie en Veiligheid (het CCV).',
    text: `
      <h3>Disclaimer</h3>
      <p>Het CCV streeft naar een zorgvuldige controle van de informatie die we via het InterventieKompas aanbieden,
        zodat deze actueel, nauwkeurig en betrouwbaar is. Desondanks kan het CCV niet garanderen dat de informatie
        in het InterventieKompas te allen tijde actueel en correct is. Het InterventieKompas pretendeert niet
        volledig te zijn.</p>
      <h3>Eigendomsvoorbehoud</h3>
      <p>Het CCV behoudt zich het recht voor alle informatie in het InterventieKompas, op ieder moment zonder
        verdere aankondiging, te wijzigen of te verwijderen. Op alle content die via het InterventieKompas te
        raadplegen is, waaronder begrepen maar niet beperkt tot de teksten, look and feel, geluids- en
        beeldmateriaal, afbeeldingen, vormgeving, merken, logo’s alsmede enige andere informatie, rusten
        intellectuele eigendomsrechten die aan het CCV dan wel zijn licentiegevers toebehoren.</p>
      <p>Deze content mag uitsluitend gebruikt worden om van het InterventieKompas en de daarop aangeboden diensten
        gebruik te maken. Zonder uitdrukkelijke voorafgaande schriftelijke toestemming van het CCV mag de content
        niet voor andere doeleinden worden aangewend, waaronder begrepen, maar niet beperkt tot het verveelvoudigen
        en/of openbaar maken van de content dan wel het verstrekken daarvan aan derden, al dan niet voor commerciële
        doeleinden, tenzij zulks dwingend rechtelijk is toegestaan.</p>
      <h3>Aansprakelijkheid</h3>
      <p>Het CCV aanvaardt geen enkele aansprakelijkheid voor schade ontstaan door het gebruik van het
        InterventieKompas, danwel uit verleende diensten, aangeboden of verwezen content in het InterventieKompas.
        Aan de verstrekte informatie kunnen geen rechten worden ontleend. </p>
      <h3>Contactgegevens</h3>
      <p>Als je vragen hebt over deze gebruikersvoorwaarden, dan kun je die stellen via <9>mail</9>.</p>
    `,
    lastUpdated:
      'Deze gebruikersvoorwaarden zijn voor het laatst aangepast op juli 2021.',
  },
  disclaimer: {
    title: 'Colofon/Disclaimer',
    managedBy: 'Deze website wordt beheerd door',
    text: `
          <h4>Tekst</h4>
          <p>Het CCV streeft naar een zorgvuldige controle van de informatie die we via het InterventieKompas aanbieden,
            zodat deze actueel, nauwkeurig en betrouwbaar is. Desondanks kan het CCV niet garanderen dat de informatie
            in het InterventieKompas te allen tijde actueel en correct is.</p>
          <h4>Afbeeldingen</h4>
          <p>De foto’s die getoond worden bij de voorbeelden van het Interventiekompas zijn in eigen beheer gemaakt,
            aangekocht of geselecteerd op rechtenvrij gebruik. Op deze foto’s kan copyright rusten.</p>
          <h4>Links</h4>
          <p>Deze website bevat links naar websites en bestanden van derden. Deze links zijn uitsluitend ter informatie.
            Het CCV heeft geen zeggenschap over deze websites en is niet verantwoordelijk of aansprakelijk voor de
            daarop aangeboden informatie, producten of diensten. Evenmin is het CCV verantwoordelijk voor verouderde
            informatie of links die niet meer actief zijn.</p>
          <h4>Aansprakelijkheid</h4>
          <p>Het CCV aanvaardt geen enkele aansprakelijkheid voor schade ontstaan door het gebruik van het
            InterventieKompas, danwel uit verleende diensten, aangeboden of verwezen content in het InterventieKompas.
            De vlekkeloze werking van het InterventieKompas wordt nagestreefd maar niet gegarandeerd. Aan de verstrekte
            informatie kunnen geen rechten worden ontleend.</p>
          <p>Bij vragen, opmerkingen en/of klachten over het InterventieKompas kun je contact opnemen met het CCV via
            <9>info@hetccv.nl</9>. Voor technische vragen of knelpunten kunt u terecht bij <10>tickets@hetccv.nl</10></p>
    `,
  },
  copyright: {
    title: 'Copyright',
    text: `
          <h4>Intellectuele eigendom</h4>
          <p>Het InterventieKompas is - in opdracht van het ministerie van Justitie en Veiligheid - ontwikkeld door en
            eigendom van het Centrum voor Criminaliteitspreventie en Veiligheid (het CCV).</p>
          <h4>Eigendomsvoorbehoud</h4>
          <p>Alle (aanspraken op) intellectuele eigendomsrechten (IE-rechten) met betrekking tot het InterventieKompas
            berusten bij het CCV.<br/>
            Op alle content die via het InterventieKompas te raadplegen is, waaronder begrepen maar niet beperkt tot de
            teksten, look and feel, geluids- en beeldmateriaal, afbeeldingen, vormgeving, merken, logo’s alsmede enige
            andere informatie, rusten intellectuele eigendomsrechten die aan het CCV toebehoren.<br/>
            Het CCV behoudt zich het recht voor alle informatie in het InterventieKompas, op ieder moment zonder verdere
            aankondiging, te wijzigen of te verwijderen.</p>
          <p>
            De gebruiker mag:
            <ul>
              <li>het Interventiekompas kopiëren, verspreiden, tonen en op- en uitvoeren;</li>
              <li>afgeleide werken maken;</li>
            </ul>
          </p>
          <p>
            onder de volgende voorwaarden:
            <ul>
              <li>naamsvermelding: de gebruiker dient de naam van het CCV te vermelden;</li>
              <li>niet-commercieel: de gebruiker mag het werk niet zonder toestemming voor commerciële doeleinden
                gebruiken;
              </li>
              <li>bij hergebruik of verspreiding dient de gebruiker de gebruiksvoorwaarden van het Interventiekompas
                kenbaar te maken aan derden;
              </li>
              <li>de gebruiker mag uitsluitend afstand doen van één of meerdere van deze voorwaarden met voorafgaande
                toestemming van de rechthebbende.
              </li>
            </ul>
          </p>
          <p>Het voorgaande laat de wettelijke beperkingen op de intellectuele eigendomsrechten onverlet.</p>
    `,
  },
  privacy: {
    menuTitle: 'Privacy en cookies',
    title: 'Privacy- en cookieverklaring',
    text0: `
          <h4>Algemeen</h4>
          <p>Het CCV verwerkt ten behoeve van het gebruik van het Interventiekompas persoonsgegevens. Het CCV maakt gebruik van de privacy- en cookieverklaring CCV, zoals vermeld op de website van het CCV, www.hetccv.nl, om te voldoen aan de informatieverplichtingen op grond van artikel 13 en 14 van de Algemene Verordening Gegevensbescherming.</p>
          <h4>Specifiek bij gebruik van het InterventieKompas</h4>
          <p>Door het InterventieKompas te gebruiken laat je persoonsgegevens bij het CCV achter die nodig zijn om je toegang tot jouw account op de website te geven. Het CCV bewaart en gebruikt deze gegevens alleen om op geaggregeerd niveau inzicht te krijgen in het gebruik van het InterventieKompas. Deze gegevens worden vertrouwelijk behandeld en niet met derden gedeeld. Het betreft de volgende gegevens:</p>
          <ul>
            <li>e-mailadres;</li>
            <li>voornaam;</li>
            <li>achternaam;</li>
            <li>organisatie;</li>
            <li>functie;</li>
            <li>gebruikersnaam;</li>
            <li>wachtwoord.</li>
          </ul>
          <p>Als je je gegevens (geanonimiseerd) ter beschikking stelt voor het verbeteren van het InterventieKompas, heeft het CCV tevens inzicht in de aangemaakte projecten. Deze gegevens worden zorgvuldig door het CCV bewaard. Het CCV gebruikt deze gegevens alleen om het InterventieKompas te verbeteren. Deze gegevens worden vertrouwelijk behandeld en niet met derden gedeeld. Als je je gegevens niet ter beschikking stelt, dan heeft het CCV geen toegang tot jouw projecten.</p>
          <p>Het CCV neemt beveiligingsmaatregelen om misbruik van en ongeautoriseerde toegang tot persoonsgegevens te beperken.</p>
          <h4>Bewaartermijn</h4>
          <p>Wanneer je ons toestemming hebt gegeven voor de verwerking van gegevens of je je ergens voor hebt aangemeld, dan verwerken wij de gegevens totdat je jouw toestemming intrekt of de gegevens zelf verwijdert. Accounts die langer dan twee jaar inactief zijn, worden verwijderd.</p>
          <h4>Inzage, wijzigen of verwijderen van je gegevens</h4>
          <p>Indien je via onze website aan ons persoonsgegevens hebt verstrekt, kun je het CCV een verzoek toesturen om deze gegevens in te zien, te wijzigen of te verwijderen. Je kunt hiervoor per e-mail contact met ons opnemen via <12>info@hetccv.nl</12>. Om misbruik te voorkomen, kunnen wij je daarbij vragen om je adequaat te identificeren.</p>
          <h4>Cookies</h4>
    `,
    text1: `
          <h4>Contactgegevens</h4>
          <p>Als je vragen hebt over jouw privacy of onze privacy- en cookieverklaring, dan kun je deze stellen aan onze functionaris voor de gegevensbescherming door te mailen naar privacy(at)hetccv.nl, of door telefonisch contact met de functionaris voor gegevensbescherming op te nemen via 030 - 751 6703.</p>
          <p>
            Stichting Centrum voor Criminaliteitspreventie en Veiligheid<br/>
            Churchilllaan 11, 18e etage<br/>
            3527 GV Utrecht
          </p>
          <p>KvK-nummer: 27268679</p>
          <p>Deze privacy- en cookieverklaring is voor het laatst aangepast in augustus 2021.</p>
    `,
  },
  contact: {
    title: 'Contact',
    ccvHelps: 'Het CCV helpt',
    text_1:
      'Het InterventieKompas is ontwikkeld door het expertiseteam <1>Gedrag & Samenleving</1> van het Centrum voor Criminaliteitspreventie en Veiligheid (CCV). Het CCV zet zich in voor een veilig en leefbaar Nederland. Naast het ontwikkelen van tools en het organiseren van opleidingen en trainingen op het gebied van effectief beleid en gedragsbeïnvloeding, levert het expertiseteam advies op maat aan departementen, markttoezichthouders, inspecties, uitvoeringsorganisaties met handhavingstaken en decentrale overheden.',
    text_2:
      'Wil je aan de slag met het InterventieKompas, maar kun je daar wel wat hulp bij gebruiken? Schakel de adviseurs van het CCV in om een sessie op maat te begeleiden. Neem contact op met Sara of Mirjam en vraag naar de mogelijkheden. Of volg de <1>CCV-training InterventieKompas</1> waar je leert hoe je het instrument kunt toepassen en wat het voor jouw organisatie kan opleveren.',
    questions: {
      title: 'Vragen',
      text: 'Voor technische vragen over het InterventieKompas kun je een mail sturen naar tickets@ccv.nl. Voor inhoudelijke vragen over het Interventiekompas kun je ook terecht bij:',
    },
  },
  adviceAndTraining: {
    title: 'Advies en training',
    processGuidance: {
      title: 'Advies',
      text: `
            Werken in het InterventieKompas vergt tijdsinvestering; een team is al gauw een dagdeel bezig met het
            invullen en analyseren van de uitkomsten. De CCV-adviseurs ondersteunen je graag bij dit proces en zijn
            bovendien experts in het gebruik van het InterventieKompas, wel zo efficiënt. Neem contact op met Sara of
            Mirjam – zie gegevens hieronder - en vraag naar de mogelijkheden van procesbegeleiding.
      `,
    },
    training: {
      title: 'Training InterventieKompas',
      text: 'Je kunt ook de <1>training InterventieKompas</1> volgen. Tijdens de training maak je kennis met het InterventieKompas. Je leert hoe het instrument werkt en hoe je het kunt gebruiken om jouw beleid of toezicht effectiever te maken. De training gaat in op het bepalen van de doelgroep, het doelgedrag en op de verschillende dimensies uit het kompas die van invloed zijn op het gedrag. Ten slotte bepaal je aan de hand van het kompas een koers.',
    },
    targetGroup: {
      title: 'Doelgroep',
      text: 'Professionals in handhaving, toezicht en beleid. Maar ook voor professionals die het onderwerp veiligheid in hun portefeuille hebben, is deze training interessant.',
    },
    trainers: {
      title: 'Adviseurs',
    },
    signUp: {
      title: 'Meld je aan',
      text: 'Interesse in de training InterventieKompas? Neem contact op met Mirjam Prinsen om de training in te plannen.',
    },
    externalTraining: {
      text: 'Meer weten over hoe je met gedragskennis gewenst gedrag bevordert? Leer het bij de incompany training <1>Gedragsverandering voor toezicht en handhaving</1>.',
    },
  },
  accessibility: {
    menuTitle: 'Toegankelijkheidsverklaring',
    title: 'Verklaring Digitale toegankelijkheid',
    text: `
          <p>Het CCV wil dat elke overheidsprofessional het InterventieKompas kan gebruiken. Kom je toch een pagina
            tegen die niet toegankelijk is? Dan kun je dit aan ons melden.</p>
          <p>Wij streven naar een optimale gebruikerservaring en goede toegankelijkheid van het Interventiekompas door
            diverse maatregelen binnen onze processen:</p>
          <ul>
            <li>Toegankelijkheid ‘by design’: toegankelijkheid is onderdeel van alle stappen in het ontwerp-, bouw en
              redactionele proces van het Interventiekompas.
            </li>
            <li>Externe evaluatie: externe deskundigen toetsen regelmatig (onderdelen van) het Interventiekompas op
              toegankelijkheid. Zowel voor de functioneel-technische onderdelen als voor de redactionele aspecten.
              Gevonden knelpunten lossen wij binnen de budgettaire mogelijkheden op.
            </li>
            <li>Kennis medewerkers: de medewerkers van de software houden hun kennis over toegankelijkheid op peil en
              passen deze toe waar nodig.
            </li>
          </ul>
    `,
    problems: {
      title: 'Problemen met toegankelijkheid melden',
      text: 'Heb je vragen of opmerkingen? Of wil je een pagina gebruiken die niet toegankelijk blijkt? Neem dan contact op met <1>info@hetccv.nl</1>.',
    },
  },
  teamMembers: {
    marit: {
      role: 'Gedragsadvieur',
    },
    sara: {
      role: 'Gedragsadvieur',
    },
  },
  register: {
    agreeToTermsAndConditions:
      'I agree to the <1>general terms and conditions</1>',
    researchOptin:
      'I also consent to the use of my (anonymised) project data for improving this tool',
    newsletterOptin: 'I would like to receive news & updates from the CCV',
    personalInformation: {
      title: 'What happens with my data?',
      text: `
        <p>Door het InterventieKompas te gebruiken laat je persoonsgegevens bij het CCV achter die nodig zijn om je toegang tot jouw account op de website te geven. Het CCV bewaart en gebruikt deze gegevens alleen om op geaggregeerd niveau inzicht te krijgen in het gebruik van het InterventieKompas. Deze gegevens worden vertrouwelijk behandeld en niet met derden gedeeld.</p>
        <p>Het betreft de volgende gegevens:</p>
        <ul>
        <li>e-mailadres;</li>
        <li>voornaam;</li>
        <li>achternaam;</li>
        <li>organisatie;</li>
        <li>functie;</li>
        <li>gebruikersnaam;</li>
        <li>wachtwoord.</li>
        </ul>
        <p>Als je je gegevens (geanonimiseerd) ter beschikking stelt voor het verbeteren van het InterventieKompas, heeft het CCV tevens inzicht in de aangemaakte projecten. Deze gegevens worden zorgvuldig door het CCV bewaard. Het CCV gebruikt deze gegevens alleen om het InterventieKompas te verbeteren. Deze gegevens worden vertrouwelijk behandeld en niet met derden gedeeld. Als je je gegevens niet ter beschikking stelt, dan heeft het CCV geen toegang tot jouw projecten.</p>
        <4>Privacy- en cookieverklaring</4>
      `,
    },
  },
  forgotPassword: {
    title: 'Forgot password',
    text: 'Enter your email address. Within a few minutes we will send you an email containing instructions for resetting your password.',
    success: {
      title: 'Email sent!',
      body: 'We have sent you an email with instructions for resetting your password.',
    },
    error: {
      invalidEmail:
        'Incorrect email address or we do not recognise you email address',
      tooManyTimes:
        "You recently requested a link to reset your password. Didn't receive anything? Try to request again later.",
    },
  },
  resetPassword: {
    title: 'Change password',
    text: 'Please fill in your email address and new password to change your password.',
    success: {
      title: 'Password changed!',
      body: 'Password changed successfully. You are now logged in and will be redirected to your project summary page in 5 seconds..',
    },
    error: {
      invalidToken:
        'The URL is incorrect or has expired, check that you have correctly copied the link from the email and try to open the link once again.',
    },
  },
  'project.new.intake.title': 'A good start',
  'project.new.intake.text':
    'Before getting started with the InterventieKompas it is a good idea to define which regulation you want to examine and which target group you have in mind. The questions below will help get this clear in your mind. You will find additional questions under the ?-buttons. These questions will help you to answer the questions. You do not need to answer the additional questions literally.',
  'content.questionnaire.intro.analysis.title': 'Target group analysis',
  'content.questionnaire.intro.analysis.text':
    'The questions below will help you gain an insight into why the target group does or does not comply with the regulation. Does it, for instance, involve intentional or unintentional behaviour? Once you have answered the questions in this list you will recognise the opportunities to improve compliance. It is important to get into the mind of the target group. This is about the target group’s perception.',
  'content.questionnaire.intro.distribution.title':
    'Subdivision of target group',
  'content.questionnaire.intro.distribution.text':
    'The following questions allow you to subdivide the target group into different compliers and offenders step by step, which will allow you to determine the right intervention strategy.\n The types of compliers are: spontaneous compliers and those deterred by enforcement. Types of offenders: unintentional offenders, occasional offenders, offenders on principle and calculating offenders. You can find more information about these types of compliers and offenders by clicking the ?-button.',
  'content.questionnaire.distribution.unknown.text':
    'Slide the circle along the line to split the 100 random members of the target group into people who are familiar with the regulation and those who do not know or understand the regulation.',
  'content.questionnaire.distribution.unintentionalComplier.text':
    "We will now zoom in on the people who are not familiar with the regulation. Slide the circle along the line to split this group in unintentional offenders and unintentional compliers. Unintentional offenders violate the regulation by accident, without realizing or because they don't understand the regulation. Unintentional compliers comply by chance, for example because they do what others do, or because it is the most logic behaviour under the circumstances.",
  'content.questionnaire.distribution.spontaneous.text':
    'We will now zoom in on the people who do know and understand the regulation. Slide the circle along the line to define how many of them comply spontaneously. Spontaneous compliers usually comply with the regulation because it coincides with their own standards, they are intrinsically motivated to comply.',
  'content.questionnaire.distribution.deterred.text':
    'We will now zoom in on the people who will not comply spontaneously. Slide the circle along the line to split the group into people who comply the regulation only because it is enforced and people who intentionally decide to violate the regulation.',
  'content.questionnaire.distribution.principled.text':
    'We will now zoom in on the people who intentionally violate the regulation. Slide the circle along the line to define how many of them offend on principle. Offenders on principle violate the regulation because they do not agree with it, or because they consider the regulation in itself is morally unacceptable or the consequences are unfair.',
  'content.questionnaire.distribution.opportunity.text':
    'We will now zoom in on the people who intentionally violate the regulation, but not on principle. Slide the circle along the line to split them into opportunistic offenders and calculating offenders. Opportunistic offenders violate the regulation because it is easy, it is difficult to resist the temptation to violate or the opportunity to violate presents itself. Calculating offenders violate because because it is advantageous to them. Therefore, these offenders perform a costs-benefits analysis. The categories are not 100% mutually exclusive. Actions may be the result of a number of motives. Choose the most dominant motive.',
  'content.questionnaire.analysis.confirm.title': 'Are you sure?',
  'content.questionnaire.analysis.confirm.text':
    'Note! You have not yet saved your answers. Make a selection below.',
  'content.project.new.confirm.title': 'Are you sure?',
  'content.project.new.confirm.text':
    'Note! You have not yet saved your answers. Make a selection below.',
  'content.questionnaire.distribution.confirm.title': 'Are you sure?',
  'content.questionnaire.distribution.confirm.text':
    'Note! You have not yet saved your answers. Make a selection below.',
};

export default content;
