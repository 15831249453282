import React, { useEffect, useState } from 'react';
import SunburstService from '../../services/SunburstService';
import useAnalysisQuestionsWithData from 'src/hooks/useAnalysisQuestionsWithData';
import { generatePath, Link, useParams } from 'react-router-dom';
import { DistributionFilterContext } from '../../context/distributionFilterContext';
import useSunburstData from 'src/hooks/useSunburstData';
import { useTranslation } from 'react-i18next';
import nl2br from 'src/utilities/string/nl2br';
import { Button } from 'react-bootstrap';

export function IntervantionListItem(intervantion) {
  const { t } = useTranslation();
  const [isToastOpen, setIsToastOpen] = useState(false);

  const showToast = () => {
    setIsToastOpen(true);
    setTimeout(() => {
      setIsToastOpen(false);
    }, 1000);
  };

  return (
    <li className="pt-2">
      <h4>{intervantion.name}</h4>
      <p
        className="m-0"
        dangerouslySetInnerHTML={{
          __html: nl2br(t('content.intervention.' + intervantion.id + '.text')),
        }}
      ></p>
      <div className="d-flex align-items-center">
        <Button
          variant="link"
          onClick={async () => {
            await navigator.clipboard.writeText(
              t('content.intervention.' + intervantion.id + '.text')
            );
            showToast();
          }}
        >
          {t('content.label.copyText')}
        </Button>
        <p
          className="m-0"
          style={
            isToastOpen ? { visibility: 'visible' } : { visibility: 'hidden' }
          }
        >
          {t('content.action.copyText')}!
        </p>
      </div>
    </li>
  );
}

export default function List({ sunburstApi }) {
  const { projectId } = useParams();
  const sunburstData = useSunburstData();
  const [zoomLevelInfo, setZoomLevelInfo] = useState();
  const { t } = useTranslation();
  const { selectedDistributionTargetGroups } = React.useContext(
    DistributionFilterContext
  );

  const doesNodeTargetExistInSelectedTargets = (node) => {
    const doesExist = selectedDistributionTargetGroups.length < 1;
    for (const selectedTarget of selectedDistributionTargetGroups) {
      if (node.target.includes(selectedTarget)) {
        return true;
      }
    }

    return doesExist;
  };

  useEffect(() => {
    if (!sunburstApi?.service) return;

    setZoomLevelInfo(sunburstApi.service.getZoomLevelInfo());

    return sunburstApi.service.subscribe(() => {
      setZoomLevelInfo(sunburstApi.service.getZoomLevelInfo());
    });
  }, [sunburstApi]);

  console.log(sunburstData.children);

  return (
    <ul className="list-group">
      {sunburstData?.children
        .filter(
          (cat) =>
            zoomLevelInfo?.level < 1 || zoomLevelInfo?.layers?.[0] === cat.id
        )
        .map((catagory, index) => (
          <li
            className="w-100 list-group-item p-0"
            key={`cat-${catagory.name}-${catagory.id}`}
          >
            <h2
              className="position-sticky w-100 p-2"
              style={{
                top: 0,
                background: catagory.color,
              }}
            >
              {catagory.name}
            </h2>
            <p className="px-4">{t(`content.category.${index + 1}.text`)}</p>
            <ul>
              {catagory.children
                .filter((mech) => {
                  if (
                    zoomLevelInfo?.level > 1 &&
                    zoomLevelInfo?.layers?.[1] !== mech.id
                  )
                    return false;

                  return doesNodeTargetExistInSelectedTargets(mech);
                })
                .map((mechanic) => (
                  <li key={`mech-${mechanic.name}-${mechanic.id}`}>
                    <h3 className="mb-2">{mechanic.name}</h3>
                    <ul>
                      {mechanic.children.map((intervantion, index) => (
                        <IntervantionListItem
                          {...intervantion}
                          key={`intervention-${intervantion.name}-${intervantion.id}`}
                        />
                      ))}
                    </ul>
                  </li>
                ))}
            </ul>
          </li>
        ))}
    </ul>
  );
}
