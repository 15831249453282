import SunburstService from '../pages/steps/result/services/SunburstService';
import { useEffect, useRef } from 'react';

/*

export type SunburtAPI = {
  initSunburst,
  getPngImage,
  setActiveDistributionTargetGroups,
  sunburstInitialised: sunburstInitialised.current,
  service: SunburstService,
}

*/

export default function useSunburst({
  labels,
  interactive = true,
  size,
  answerCircleSize,
  onOpenModal,
  onZoomStart,
  onZoomFinished,
  meantForDownload = false,
}) {
  const sunburstService = useRef(null);
  const sunburstInitialised = useRef(false);

  function initSunburstService() {
    sunburstService.current = new SunburstService({
      labels,
      interactive,
      size,
      answerCircleSize,
      onOpenModal,
      onZoomStart,
      onZoomFinished,
      meantForDownload,
    });
  }

  function initSunburst(element, sunburstData, analysisQuestions, projectType) {
    if (sunburstService.current) {
      sunburstService.current.initialiseSunburst(
        element,
        sunburstData,
        analysisQuestions,
        projectType
      );
      sunburstInitialised.current = true;
    }
  }

  function getPngImage(callback) {
    if (sunburstInitialised.current) {
      sunburstService.current.getPngImage(callback);
    }
  }

  useEffect(() => {
    if (!sunburstService.current) {
      initSunburstService();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function setActiveDistributionTargetGroups(distributionTargetGroups) {
    if (sunburstService.current) {
      sunburstService.current.setActiveDistributionTargetGroups(
        distributionTargetGroups
      );
    }
  }

  return {
    initSunburst,
    getPngImage,
    setActiveDistributionTargetGroups,
    sunburstInitialised: sunburstInitialised.current,
    service: sunburstService.current,
  };
}
