import React from 'react';

import styles from './infoBtn.module.scss';

function InfoBtn({ onClick, id }) {
  return (
    <button
      id={id}
      type="button"
      className={styles.infoButton}
      onClick={onClick}
    >
      ?
    </button>
  );
}

export default InfoBtn;
